import React from "react";
import {
    LandingPageBg,
    LandingPageContainer,
    LandingPageContent,
    LandingPageImage, LandingPageText, LandingPageTitle
} from "./LandingPageElements";
import background from '../../images/landing_page.jpeg';

const LandingPage = () => {
    return (
        <>
            <LandingPageContainer>
                <LandingPageBg>
                    <LandingPageImage src={background} className="App-logo" alt="logo" />
                </LandingPageBg>
                <LandingPageContent>
                    <LandingPageTitle>
                        Introductable
                    </LandingPageTitle>
                    <LandingPageText>

                        <br />
                        get rewarded
                        <br />
                        for who you know
                        <br />
                    </LandingPageText>
                </LandingPageContent>
            </LandingPageContainer>
        </>
    )
}

export default LandingPage;
