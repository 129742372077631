import './App.css';
import React, {useLayoutEffect} from "react";
import {BrowserRouter, Routes, Route, useLocation} from "react-router-dom"
import LandingPage from "./components/landingpage/LandingPage";
import Home from "./components/home/Home";

function App() {

  const ScrollToTop = ({children}) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
  }

  return (
      <>
        <BrowserRouter>
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<Home />}>
                <Route index element={<LandingPage />} />
                <Route path="work" element={<LandingPage />} />
                <Route path="contact" element={<LandingPage />} />
              </Route>
            </Routes>
          </ScrollToTop>
        </BrowserRouter>
      </>
  );
}

export default App;
