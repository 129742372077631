import React from 'react';
import {
    CloseIcon,
    Icon,
    SidebarContainer,
    SidebarLink,
    SidebarMenu,
    SidebarRouterLink,
    SidebarWrapper
} from "./SideBarElements";
import {FaFacebook, FaInstagram, FaLinkedin, FaTwitter} from "react-icons/fa";

const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarRouterLink to='/work' onClick={toggle}>
                        HOW IT WORKS?
                    </SidebarRouterLink>
                    <SidebarRouterLink to='/contact' onClick={toggle}>
                        CONTACT
                    </SidebarRouterLink>
                    <SidebarLink href={'https://www.introductable.com/introductable'} target="_blank" rel="noopener noreferrer" onClick={toggle}>
                        <FaLinkedin size={35} />
                    </SidebarLink>
                    <SidebarLink href={'https://www.introductable.com/introductable'} target="_blank" rel="noopener noreferrer" onClick={toggle}>
                        <FaTwitter size={35} />
                    </SidebarLink>
                    <SidebarLink href={'https://www.instagram.com/introductable'} target="_blank" rel="noopener noreferrer" onClick={toggle}>
                        <FaInstagram size={35} />
                    </SidebarLink>
                    <SidebarLink href={'https://www.facebook.com/introductable'} target="_blank" rel="noopener noreferrer" onClick={toggle}>
                        <FaFacebook size={35} />
                    </SidebarLink>
                </SidebarMenu>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar;
